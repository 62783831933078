import {graphql} from 'gatsby';
import {AxiosError} from 'axios';
import React, {useState, FormEvent, useEffect} from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import getRedirectUrl from '../lib/get-redirect-url';
import {OAuthPageQuery} from './__generated__/OAuthPageQuery';
import {Gutters, GutterMaxWidth} from '../components/Spacing/Gutters';
import getQueryParameter from '../utils/get-query-parameters';
import {FooterContent} from '../types/FooterContent';
import {AuthCode} from '../components/AuthCode';
import styled from 'styled-components';
import {LoadingIcon} from '../components/Icon/LoadingIcon';
import {Theme} from '../theme/Theme';
import {ErrorMessage} from '../components/ErrorMessage';
import {createApi} from '../lib/api';
import {UserApi} from '@focusrite-novation/ampify-api';
import {urlForState} from '../utils/login-url';

const {space} = Theme;

const redirect = (url: string) => window.location.replace(url);

interface PageProps<T> {
  data: T;
  pageContext: {
    contentfulFooterContent: FooterContent;
    customerPortalBaseUrl: string;
  };
}

const Redirect = styled.div`
  width: 100%;
  padding: 0 20%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${space[7]}px;
  padding-bottom: ${space[5]}px;
`;

const OAuthPage = (props: PageProps<OAuthPageQuery>) => {
  const [error, setError] = useState<AxiosError>();
  const [oauthState, setOauthState] = useState('');
  const siteMetadata = props?.data?.site?.siteMetadata;
  const apiBaseUrl = siteMetadata?.ampifyApi?.baseUrl as string;
  const [time, setTime] = useState(120);

  useEffect(() => {
    (async () => {
      const state = getQueryParameter(window, 'state');
      setOauthState(state);

      if (state == 'none') {
        return;
      }
      const auth = siteMetadata?.auth;
      if (!auth || !auth.client_id || !auth.scope) {
        throw new Error('No oauth settings configured');
      }
      const authOpts = {
        client_id: auth.client_id,
        scope: auth.scope,
      };
      try {
        const userApi = createApi(UserApi, apiBaseUrl, authOpts);
        const redirectUrl = await getRedirectUrl(
          userApi,
          authOpts,
          state,
          getQueryParameter(window, 'code')
        );

        if (redirectUrl) {
          redirect(redirectUrl);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        setError(axiosError);
      }
    })();
  }, []);

  useEffect(() => {
    if (time == 0) {
      redirect(urlForState(props.pageContext.customerPortalBaseUrl));
    }
    const timer = setInterval(() => {
      setTime(time - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [time]);

  const code: string =
    typeof window === 'undefined'
      ? ''
      : getQueryParameter(window, 'code') || '';

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    (document.getElementById('code') as HTMLTextAreaElement).select();
    document.execCommand('copy');
  };

  return (
    <Layout
      {...props.pageContext.contentfulFooterContent}
      customerPortalBaseUrl={props.pageContext.customerPortalBaseUrl}
      fadeIn={false}
    >
      <SEO title="Activation Page" />
      <Gutters>
        {error ? (
          <ErrorMessage error={error} maxWidth={GutterMaxWidth.LARGE} />
        ) : oauthState === 'none' ? (
          <AuthCode code={code} onSubmit={onSubmit} timeout={time} />
        ) : (
          <Gutters>
            <Redirect>
              <h3 data-testid="redirect-message">
                Please wait while we redirect you...
              </h3>
            </Redirect>
            <LoadingIcon width="100%" />
          </Gutters>
        )}
      </Gutters>
    </Layout>
  );
};

export const pageQuery = graphql`
  query OAuthPageQuery {
    site(siteMetadata: {}) {
      siteMetadata {
        ampifyApi {
          baseUrl
        }
        auth {
          client_id
          scope
        }
      }
    }
  }
`;

export default OAuthPage;
