import {UserApi} from '@focusrite-novation/ampify-api';
import {getUserWithAuthCode, OAuthOptions} from '../utils/auth';
import {actions} from '../utils/login-url';

export default async function getRedirectUrl(
  userApi: UserApi,
  oauthOpts: OAuthOptions,
  stateParameter: string,
  authCode: string
) {
  const state = JSON.parse(atob(decodeURIComponent(stateParameter)));
  await getUserWithAuthCode(userApi, authCode, oauthOpts);

  let options = {
    productName: state.productName,
    softwareId: state.softwareId,
    itemId: state.itemId,
    planId: state.planId,
    slug: state.slug,
  };

  if (!options.planId && !!options.itemId) {
    delete options.planId;
  }
  if (!options.itemId && !!options.planId) {
    delete options.itemId;
  }

  if (state.action === actions.buy) {
    return makeRedirect(options);
  }

  if (state.action === actions.myAccount) {
    return '/my-account';
  }
}

type RedirectOptions = {[key: string]: string};

function makeRedirect(options: RedirectOptions): string | undefined {
  const invalidValues = Object.values(options).filter(
    (value) => typeof value !== 'string'
  );

  if (invalidValues.length > 0) {
    return undefined;
  }

  const params = Object.keys(options)
    .map((key) => `${key}=${encodeURIComponent(options[key])}`)
    .join('&');

  return `/payment?${params}`;
}
