import styled from 'styled-components';
import Theme from '../../theme';
import {InputFieldProps} from './InputField';

const {colours, fonts, fontSizes} = Theme;

export const StyledInputField = styled.input<InputFieldProps>`
  font-family: ${fonts.body};
  font-size: ${fontSizes[3]}px;
  top: 632px;
  left: 260px;
  width: 612px;
  height: 65px;
  border: 1px solid ${colours.grey};
  padding: 20px;
`;
