import styled from 'styled-components';
import {Theme} from '../../theme/Theme';
const {colours, fontSizes, fonts} = Theme;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const StyledText = styled.span`
  text-align: left;
  font-family: ${fonts.body};
  font-size: ${fontSizes[2]}px;
  color: ${colours.black};
`;

export const AboutWrapper = styled.div`
  max-width: 380px;
  padding: 32px 0;
`;
