import {
  OAuthTokenResponse,
  User,
  UserPurchases,
} from '@focusrite-novation/ampify-api';

const USER_STORAGE_KEY = 'user';
const TOKEN_STORAGE_KEY = 'token';
const PURCHASES_STORAGE_KEY = 'purchases';

export const isSubscribedToPlan = (planId: string) =>
  getUserFromLocalStorage()?.plans?.includes(planId);

function getItemFromLocalStorage<Item>(
  isItemValid: (item: Item | null) => boolean,
  storageKey: string
): Item | null {
  try {
    const item = localStorage.getItem(storageKey);
    if (typeof item === 'string') {
      const parsedItem: Item = JSON.parse(item);
      if (isItemValid(parsedItem)) {
        return parsedItem;
      }
    }
    if (item === null) {
      return null;
    }
    throw new Error(`Invalid data found in ${storageKey}`);
  } catch (error) {
    throw new Error(`Invalid item found in ${storageKey}`);
  }
}

export const getTokenFromLocalStorage = (): OAuthTokenResponse | null =>
  getItemFromLocalStorage(
    (token: OAuthTokenResponse | null) =>
      typeof token?.access_token === 'string',
    TOKEN_STORAGE_KEY
  );

export const getUserFromLocalStorage = (): User | null =>
  getItemFromLocalStorage(
    (user: User | null) => typeof user?.email === 'string',
    USER_STORAGE_KEY
  );

function writeItemToLocalStorage<Item>(item: Item, storageKey: string) {
  const parsedItem = JSON.stringify(item);
  localStorage.setItem(storageKey, parsedItem);
}

export const writeTokenToLocalStorage = (token: OAuthTokenResponse) =>
  writeItemToLocalStorage(token, TOKEN_STORAGE_KEY);

export const writeUserToLocalStorage = (user: User) =>
  writeItemToLocalStorage(user, USER_STORAGE_KEY);

export const clearTokenFromLocalStorage = () =>
  localStorage.removeItem(TOKEN_STORAGE_KEY);

export const clearUserFromLocalStorage = () => {
  localStorage.removeItem(USER_STORAGE_KEY);
};
