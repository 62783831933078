import {
  Configuration,
  OAuthTokenResponse,
  tokenRefresher,
} from '@focusrite-novation/ampify-api';
import {BaseAPI} from '@focusrite-novation/ampify-api/dist/generated/base';
import {OAuthOptions} from '../utils/auth';
import {
  getTokenFromLocalStorage,
  writeTokenToLocalStorage,
} from '../utils/user-info';

interface CreateApiOptions {
  forceRefreshToken: boolean;
}

export const createApi = <T extends BaseAPI>(
  apiType: {new (config: Configuration): T},
  apiBaseUrl: string,
  oauthOptions: OAuthOptions,
  apiOptions: CreateApiOptions = {forceRefreshToken: false}
): T => {
  const config = getApiConfig(apiBaseUrl);
  config.apiKey = refreshingToken({
    oauthOptions,
    config,
    force: apiOptions.forceRefreshToken,
  });
  return new apiType(config);
};

const getApiConfig = (apiBaseUrl: string): Configuration => {
  return {
    basePath: apiBaseUrl,
    isJsonMime: (s: string) => true,
  };
};

interface RefreshingTokenOptions {
  oauthOptions: OAuthOptions;
  config: Configuration;
  force?: boolean;
}

const refreshingToken = ({
  oauthOptions,
  config,
  force = false,
}: RefreshingTokenOptions): (() => Promise<string>) =>
  tokenRefresher({
    clientId: oauthOptions.client_id,
    tokenAccessor: () => getTokenFromLocalStorage() as OAuthTokenResponse,
    tokenSetter: writeTokenToLocalStorage,
    scope: oauthOptions.scope,
    config,
    force,
  });
