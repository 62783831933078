import React, {FormEvent, useEffect, useState} from 'react';
import {InputField} from '../InputField';
import {Button} from '../Button';
import {AboutWrapper, Form, StyledText} from './AuthCode.styles';
import {Spacer} from '../Spacing/Spacer';
import {Gutters} from '../Spacing/Gutters';

interface AuthCodeProps {
  code: string;
  timeout: number;
  onSubmit: (event: FormEvent) => void;
}

export const AuthCode = (props: AuthCodeProps) => (
  <Gutters paddingTop>
    <AboutWrapper data-testid="auth-code-about">
      <h1>Your activation code</h1>
      <StyledText>
        Here is the code you’ll need to activate your plugin. Paste this code
        into the plugin window where prompted. This code will expire in{' '}
        {props.timeout}s.
      </StyledText>
    </AboutWrapper>
    <Form onSubmit={props.onSubmit} data-testid="auth-code-form">
      <InputField
        id="code"
        readOnly={true}
        placeholder="Your activation code"
        value={props.code}
        data-testid="auth-code-input"
        contentEditable={false}
        spellCheck={false}
      />
      <Spacer />
      <Button type="submit" data-testid="auth-code-copy">
        Copy activation code
      </Button>
    </Form>
  </Gutters>
);
